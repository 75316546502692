import * as React from "react"
import { HeadFC } from "gatsby"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl-v4"
import classNames from "classnames";


const imageStyles = {
  width: '100%'
}

const ProjectList = ({ title, list }) => {
  const intl = useIntl()
  return (
    <main>
      <div className={'project-list formatted-text'} style={{marginBottom: '40px'}}>
        <h1 style={{marginBottom: '28px', marginTop: '220px', textAlign: 'left'}} className={classNames({hyphens: intl.locale == 'fi'})}>{title}</h1>
        <div className={'project-list__ig-grid'}>
            {list.map((project, index) =>
              <Link key={index} projectId={project.internalName} to={'/project/' + project.internalName.toLowerCase().replace(/ /g,"-")}>
              <div>
                <img src={project.mainImage.url + '?w=900&h=675&fm=jpg&q=40&fit=fill'} style={imageStyles} />
              </div>
              <div style={{marginTop: '4px', textAlign: 'left'}}>{project.name}</div>
              </Link>
            )}
        </div>
      </div>
    </main>
  )
}



export default ProjectList
