import React, { Component } from 'react'

export function GlobalHead({title, description}) {
  const siteTitle = title ? title : 'NOAN'
  const metaDescription = description ?
    description :
    "NOAN is a Finnish Architecture office focusing on urban design and public buildings. Our strength is an open-minded approach to architecture and the built environment."
  return (
    <>
      <title>{siteTitle}</title>
      <meta name="description" content={metaDescription} />
      <script src="https://use.typekit.net/rin2afl.js" />
      <script>{`try{Typekit.load({ async: true });}catch(e){}`}</script>
      <script async defer src="https://scripts.simpleanalyticscdn.com/latest.js"></script>
    </>
  )
}

export default GlobalHead
