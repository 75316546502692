import React, { Component } from 'react'
import { useIntl, Link, FormattedMessage, changeLocale } from "gatsby-plugin-intl-v4"
import $ from 'jquery'
import classNames from 'classnames'


const Nav = ({ location, style }) => {

  var isShowing = false
  const intl = useIntl()

  const toggleLanguage = () => {
    intl.locale == 'en' ? changeLocale('fi') : changeLocale('en')
  }

  const toggleMobileMenu = () => {
    if (!isShowing) {
      $('.checkbox').prop('checked', false)
      $('.nav-logo').hide()
      $('.mobile-menu')
        .clearQueue()
        .stop()
        .css('display', 'flex')
        .animate({opacity: 1.0}, 300, () => { isShowing = true })
    } else  {
      $('.checkbox').prop('checked', true)
      $('.nav-logo').show()
      $('.mobile-menu')
        .clearQueue()
        .stop()
        .animate({opacity: 0.0}, 100, () => { $('.checkbox').prop('checked', false); isShowing = false })
    }
  }

  return (
    <>
      <nav style={style}>
         <div className={'nav-juttu nav-info nav-start'}>
           <Link to={ '/urban' } className={'nav-item-urban'}>
              {intl.formatMessage({ id: "urbanNav" })}
           </Link>
           <Link to={ '/public' } className={'nav-item-sacral'}>
              {intl.formatMessage({ id: "publicNav" })}
           </Link>
           <Link to={ '/housing' } className={'nav-item-housing'}>
              {intl.formatMessage({ id: "housingNav" })}
           </Link>
         </div>

         <Link to={'/' }>
           <div className={'nav-juttu nav-logo-wrapper'}>
             <div className={'nav-logo-wrapper-inner'}>
               <span className={'noan nav-logo notranslate'}>
                 NOA
                 <span className={'last-letter'}>N</span>
               </span>
             </div>
           </div>
         </Link>
         <div className={'nav-juttu nav-info nav-end'}>
           <Link to={ '/info' }>
             info
           </Link>
             <span onClick={toggleLanguage}>
               <span className={ classNames('lang-icon', {'lang-icon-selected': intl.locale == 'en'})}>en</span>
               <span className={'nav__lang-icon-slash'} onClick={toggleLanguage}> / </span>
               <span className={ classNames('lang-icon', {'lang-icon-selected': intl.locale == 'fi'})} onClick={toggleLanguage}>fi</span>
             </span>
             <div>
              <a href={'https://www.instagram.com/architecturestudio_noan'} className={'nav__socmed-icon'} target='_blank'>
                <i className={'fa fa-2x fa-instagram'} aria-hidden='true' />
              </a>
            </div>
         </div>
         <div className={'nav-juttu mobile-icon'}>
           <a href={'https://www.instagram.com/architecturestudio_noan'} style={{ paddingRight: '20px' }} className={'nav__socmed-icon'} target='_blank'>
             <i className={'fa fa-2x fa-instagram'} aria-hidden='true' />
           </a>
           <input type="checkbox" id="checkbox" className="checkbox hidden" />
              <label htmlFor="checkbox">
                  <div className={'hamburger hamburger-animation'} onClick={toggleMobileMenu}>
                      <span className={'bar bar1'}></span>
                      <span className={'bar bar2'}></span>
                      <span className={'bar bar3'}></span>
                      <span className={'bar bar4'}></span>
                  </div>
              </label>
          </div>
       </nav>
       <div className={'mobile-menu'}>
         <Link to={ '/urban' } onClick={toggleMobileMenu}>
           <h1 className={'menu-item'}>
              {intl.formatMessage({ id: "urbanMenu" })}
           </h1>
         </Link>
         <Link to={ '/public' } onClick={toggleMobileMenu}>
           <h1 className={'menu-item'}>
              {intl.formatMessage({ id: "publicMenu" })}
           </h1>
         </Link>
         <Link to={ '/housing' } onClick={toggleMobileMenu}>
           <h1 className={'menu-item'}>
              {intl.formatMessage({ id: "housingMenu" })}
           </h1>
         </Link>
         <Link to={ '/info' } onClick={toggleMobileMenu}>
           <h1 className={'menu-item'}>
               Info
           </h1>
         </Link>
          <h1 className={'menu-item'}>
            <span className={ classNames('lang-icon', {'lang-icon-selected': intl.locale == 'en'})} onClick={toggleLanguage}>en /&nbsp;</span>
            <span className={ classNames('lang-icon', {'lang-icon-selected': intl.locale == 'fi'})} onClick={toggleLanguage}> fi</span>
           </h1>
       </div>
     </>
  )
}

export default Nav
